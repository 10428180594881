import { useEffect, useState } from 'react';
import { DownloadIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/header/header.component';
import ModuleSummary from '../../components/module-summary/module-summary.component';
import { TableRow } from '../../types/table-row.type';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';
import Table from '../../components/table/table.component';
import { User, UserCreation } from '../../types/user.type';
import UserSearchFilter from '../../components/user-search-filter/user-search-filter.component';
import HDivider from '../../components/h-divider/h-divider.component';
import BasicButton from '../../components/basic-button/basic-button.component';
import { biSdk } from '../../services/bi.service';
import { useThemeStore } from '../../store';
import {
    TNController,
    ToastNotification,
} from '../../components/toast-notification/toast-notification.component';
import PlusIcon from '../../icons/plus.icon';
import { initShortcut, removeShortcut } from '../../utils/shortcut.util';
import FlatButton from '../../components/flat-button/flat-button';

import CreateUserModal from './containers/create-user-modal';
import { mapRows, totalsMapper } from './utils';
import styles from './users.module.scss';
import ExportUsersModal from './containers/export-users-modal';
import { generateDownloadableFile } from '../../utils/download.util';

const UsersView = () => {
    const navigate = useNavigate();
    const { theme } = useThemeStore();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        count: 10,
    });
    const [searchParam, setSearchParam] = useState('');
    const [tableRows, setTableRows] = useState<TableRow[]>([]);
    const [totalSummary, setTotalSummary] = useState([
        { label: 'Total usuarios', value: '-' },
        { label: 'Usuarios activos', value: '-' },
        { label: 'Usuarios inactivos', value: '-' },
        { label: 'Sin suscripción', value: '-' },
    ]);
    const [userCreation, setUserCreation] = useState({
        shown: false,
        loading: false,
    });
    const [exportUsers, setExportUsers] = useState({
        shown: false,
        loading: false,
    });

    useEffect(() => {
        initShortcut('n', showCreationModal);

        return () => removeShortcut(showCreationModal);
    }, []);

    useEffect(() => {
        getTotals();
        fetchUsers({
            search: searchParam,
            page: pagination.currentPage,
            count: pagination.count,
        });
    }, [searchParam]);

    const updateTableData = (data: any) => {
        setPagination((pagination) => ({
            ...pagination,
            currentPage: Number(data.page),
            totalPages:
                data.total === 0 ? 1 : Math.ceil(data.total / pagination.count),
        }));
        mapUserRows(data.result);
    };

    const mapUserRows = (users: User[]) => {
        setTableRows(mapRows(users));
    };

    const fetchUsers = async (queryParams?: any) => {
        const response = await userSdk.getAll(queryParams);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('Error getting users:', response);
        }
        return updateTableData(response.data);
    };

    const getTotals = async () => {
        const response = await biSdk.getUserTotals();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('Error getting totals:', response);
        }

        const totals = response.data;
        const summary = Object.keys(totals).map((key) => ({
            label: totalsMapper[key],
            value: totals[key],
        }));
        setTotalSummary(summary);
    };

    const goToPage = (page: number) => {
        if (page < 1 || page > pagination.totalPages) return;

        fetchUsers({
            search: searchParam,
            page: page,
            count: 10,
        });
    };

    const goToUserDetails = (id: number) => navigate(`/users/${id}`);

    const goToCreateSubscription = () => navigate('/subscriptions/create');

    const showCreationModal = () => {
        if (userCreation.shown) return;
        setUserCreation((prev) => ({ ...prev, shown: true }));
    };
    const hideCreationModal = () => {
        setUserCreation((prev) => ({ ...prev, shown: false }));
    };

    const showExportUsersModal = () => {
        setExportUsers((prev) => ({ ...prev, shown: true }));
    };

    const hideExportUsersModal = () => {
        setExportUsers((prev) => ({ ...prev, shown: false }));
    };

    const createUser = async (data: UserCreation) => {
        setUserCreation({
            shown: true,
            loading: true,
        });
        const response = await userSdk.createUserByAdmin(data);
        if (response.code !== ResponseCodes.CREATED) {
            setUserCreation({
                shown: true,
                loading: false,
            });
            return TNController.show(response.message, 2500, 'error');
        }
        setUserCreation({
            shown: false,
            loading: false,
        });
        TNController.show('Usuario creado exitosamente', 2500, 'success');
    };

    const downloadUsers = async (type: string) => {
        setExportUsers({ shown: true, loading: true });
        const response = await biSdk.downloadUsers(type);
        setExportUsers({ shown: false, loading: false });
        if (!response) return;
        generateDownloadableFile(response, `${type}-users.xlsx`);
    };

    return (
        <main className={styles.view}>
            <Header
                title={'Usuarios'}
                subtitle={'Aquí puedes ver todos los usuarios'}
            />
            <ModuleSummary mode={theme} summary={totalSummary} />
            <article className={styles.mainButtonsContainer}>
                <BasicButton onClick={showCreationModal} shortcut={'N'}>
                    <PlusIcon size={12} />
                    <span>Crear usuario</span>
                </BasicButton>
                <BasicButton
                    text={'Crear suscripción'}
                    onClick={goToCreateSubscription}
                />
                <FlatButton onClick={showExportUsersModal}>
                    <DownloadIcon size={16} />
                    Exportar
                </FlatButton>
            </article>
            <HDivider />
            <UserSearchFilter mode={theme} onSearch={setSearchParam} />
            <Table
                mode={theme}
                header={[
                    'ID',
                    'Nombre',
                    'Email',
                    'Teléfono',
                    'Cédula',
                    '¿Suscripción?',
                    'Fecha creación',
                ]}
                data={tableRows}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onPageChange={goToPage}
                onItemSelected={goToUserDetails}
            />
            {userCreation.shown && (
                <CreateUserModal
                    loading={userCreation.loading}
                    onCreate={createUser}
                    onDiscard={hideCreationModal}
                />
            )}
            {exportUsers.shown && (
                <ExportUsersModal
                    loading={exportUsers.loading}
                    onDownload={downloadUsers}
                    onDiscard={hideExportUsersModal}
                />
            )}
            <ToastNotification />
        </main>
    );
};

export default UsersView;
